* {
    box-sizing: border-box;
    overflow-scrolling: touch;
    -webkit-overflow-scrolling: touch; }
html {
    font-family: 'Raleway','sans-serif';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    overflow-scrolling: touch;
    -webkit-overflow-scrolling: touch;
    margin-top: 0 !important;
    margin: 0;
    //width: 100%
    //min-height: 100vh
    //overflow-y: auto
    //+breakpoint(md)
 }    //  //overflow-x: hidden

body {
    margin: 0;
    width: 100%;
    //height: 100vh
    overflow-x: hidden;
    // overflow-y: auto
    &.nav-is-open {
        position: fixed;
        .header {
            background: white;

            &__logo {
                svg {
                    color: black; } } }
        .nav__toggle {
            span {
                border-bottom: 2px solid $primary !important; } } } }

#inner-wrapper {
    // min-height: 100vh
    // height: 100vh
 }    // width: 100vh
#outer-wrapper {
    // height: 100vh
    // min-height: 100vh
    // height: 100%
    // &.is-transitioning
    //     main
 }    //         position: absolute
main {
    // visibility: visible !important
    min-height: 100vh;
    width: 100%;
    padding-bottom: 86px;
    @include breakpoint(sm) {
        padding-bottom: 300px; }
    //height: 100%
    // display: flex
 }    // flex-direction: column

img {
    max-width: 100%;
    height: auto; }

.page-container {
    position: relative;
    min-height: 100vh; }

.page {
    //padding-bottom: 260px
    //position: relative
 }    //min-height: 100vh

.container {
    @include breakpoint(xl) {
        padding-left: vw(140);
        padding-right: vw(140); }
    @include breakpoint(dt) {
        padding-left: vw(120);
        padding-right: vw(120); }
    @include breakpoint(md) {
        padding-left: 40px;
        padding-right: 40px; }
    @include breakpoint(sm) {
        padding-left: 30px;
        padding-right: 30px; } }



// WORDPRESS CLASSES


.alignleft {
    float: left;
    margin-right: 20px; }

.alignright {
    float: right;
    margin-left: 20px; }

.right {
    float: right; }
.left {
    float: left; }

.size-thumbnail {}

.size-full {}

// POSITIONS

.u-no-margin {
    margin: 0; }

.u-full-height {
    min-height: 80vh; }
.flex-center {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    &>.container {
        flex-grow: 1; } }

.flex-vertical {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start; }
.flex-column {
    display: flex;
    flex-flow: column;
    align-items: center; }
.hide {
    display: none; }

.hidden {
    // visibility: hidden !important
    pointer-events: none !important; }

.visible {
    pointer-events: all !important;
    display: flex !important;
 }    // visibility: visible !important


.no-alpha {
    opacity: 0 !important; }

.u-center-text {
    text-align: center; }
.u-text-left {
    text-align: left; }
.u-text-right {
    text-align: right; }
.u-center {
    margin: 0 auto;
    display: block; }

// FONTS
.u-italic-text {
    font-style: italic; }
.u-light-text {
    font-weight: 300; }

.u-orange-text {
    color: $orange; }

.u-text-light {
    font-weight: 200; }

.u-semibold {
    font-weight: 500; }


.zoomable {
    transition: all .25s;
    backface-visibility: hidden;
    @include breakpoint(dt) {
        &:hover {
            transform: scale(1.05); } }
    &-svg {
        svg {
            @include transition; }
        @include breakpoint(dt) {
            &:hover {
                svg {
                    transform: scale(1.1); } } } }
    &-img {
        img {
            @include transition; }
        @include breakpoint(dt) {
            &:hover {
                img {
                    transform: scale(1.2); } } } } }

.z-depth-1 {
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2); }
.z-depth-2 {
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3); }
.z-depth-3 {
    box-shadow: 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.3); }
.z-depth-4 {
    box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.3); }
.z-depth-5 {
    box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.3); }

button {
    border: none;
    outline: 0; }


.divider {
    display: block;
    width: 100%;
    height: 2px;
    background: black;
    margin: 2.5rem 0;
    &-short {
        @extend .divider;
        width: 60px;
        &-white {
            @extend .divider-short;
            background: white; }
        &-grey {
            @extend .divider-short;
            background: #979797; } }
    &-white {
        @extend .divider;
        background: white; } }


// CUSTOM

.u-full-height {}

.row.no-padding {
    margin: 0;
    *[class^="col"] {
        padding: 0; } }
